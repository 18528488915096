// Name:            Countdown
// Description:     Component to create countdown timers
//
// Component:       `uk-countdown`
//
// Sub-objects:     `uk-countdown-number`
//                  `uk-countdown-separator`
//                  `uk-countdown-label`
//
// ========================================================================


// Variables
// ========================================================================

@countdown-number-line-height:                  0.8;
@countdown-number-font-size:                    2rem; // 32px
@countdown-number-font-size-s:                  4rem; // 64px
@countdown-number-font-size-m:                  6rem; // 96px

@countdown-separator-line-height:               1.6;
@countdown-separator-font-size:                 1rem; // 16px
@countdown-separator-font-size-s:               2rem; // 32px
@countdown-separator-font-size-m:               3rem; // 48px


/* ========================================================================
   Component: Countdown
 ========================================================================== */

.uk-countdown {
    .hook-countdown();
}


/* Item
 ========================================================================== */

.uk-countdown-number,
.uk-countdown-separator {
    .hook-countdown-item();
}


/* Number
 ========================================================================== */


/*
 * 1. Make numbers all of the same size to prevent jumping. Must be supported by the font.
 * 2. Style
 */

.uk-countdown-number {
    /* 1 */
    font-variant-numeric: tabular-nums;
    /* 2 */
    font-size: @countdown-number-font-size;
    line-height: @countdown-number-line-height;
    .hook-countdown-number();
}

/* Phone landscape and bigger */
@media (min-width: @breakpoint-small) {

    .uk-countdown-number { font-size: @countdown-number-font-size-s; }

}

/* Tablet landscape and bigger */
@media (min-width: @breakpoint-medium) {

    .uk-countdown-number { font-size: @countdown-number-font-size-m; }

}


/* Separator
 ========================================================================== */

.uk-countdown-separator {
    font-size: @countdown-separator-font-size;
    line-height: @countdown-separator-line-height;
    .hook-countdown-separator();
}

/* Phone landscape and bigger */
@media (min-width: @breakpoint-small) {

    .uk-countdown-separator { font-size: @countdown-separator-font-size-s; }

}

/* Tablet landscape and bigger */
@media (min-width: @breakpoint-medium) {

    .uk-countdown-separator { font-size: @countdown-separator-font-size-m; }

}


/* Label
 ========================================================================== */

.uk-countdown-label {
    .hook-countdown-label();
}


// Hooks
// ========================================================================

.hook-countdown-misc();

.hook-countdown() {}
.hook-countdown-item() {}
.hook-countdown-number() {}
.hook-countdown-separator() {}
.hook-countdown-label() {}
.hook-countdown-misc() {}


// Inverse
// ========================================================================

.hook-inverse() {

    .uk-countdown-number,
    .uk-countdown-separator {
        .hook-inverse-countdown-item();
    }

    .uk-countdown-number {
        .hook-inverse-countdown-number();
    }

    .uk-countdown-separator {
        .hook-inverse-countdown-separator();
    }

    .uk-countdown-label {
        .hook-inverse-countdown-label();
    }

}

.hook-inverse-countdown-item() {}
.hook-inverse-countdown-number() {}
.hook-inverse-countdown-separator() {}
.hook-inverse-countdown-label() {}
