// Name:            Totop
// Description:     Component to create an icon to scroll back to top
//
// Component:       `uk-totop`
//
// ========================================================================


// Variables
// ========================================================================

@totop-padding:                                 5px;
@totop-color:                                   @global-muted-color;

@totop-hover-color:                             @global-color;

@totop-active-color:                            @global-emphasis-color;


/* ========================================================================
   Component: Totop
 ========================================================================== */

/*
 * Addopts `uk-icon`
 */

.uk-totop {
    padding: @totop-padding;
    color: @totop-color;
    .hook-totop();
}

/* Hover */
.uk-totop:hover {
    color: @totop-hover-color;
    .hook-totop-hover();
}

/* OnClick */
.uk-totop:active {
    color: @totop-active-color;
    .hook-totop-active();
}


// Hooks
// ========================================================================

.hook-totop-misc();

.hook-totop() {}
.hook-totop-hover() {}
.hook-totop-active() {}
.hook-totop-misc() {}


// Inverse
// ========================================================================

@inverse-totop-color:                       @inverse-global-muted-color;
@inverse-totop-hover-color:                 @inverse-global-color;
@inverse-totop-active-color:                @inverse-global-emphasis-color;

.hook-inverse() {

    .uk-totop {
        color: @inverse-totop-color;
        .hook-inverse-totop();
    }

    .uk-totop:hover {
        color: @inverse-totop-hover-color;
        .hook-inverse-totop-hover();
    }

    .uk-totop:active {
        color: @inverse-totop-active-color;
        .hook-inverse-totop-active();
    }

}

.hook-inverse-totop() {}
.hook-inverse-totop-hover() {}
.hook-inverse-totop-active() {}
