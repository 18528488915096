//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@internal-text-background-color-gradient:         linear-gradient(90deg, @text-background-color 0%, spin(@text-background-color, 40%) 100%);


// Style modifiers
// ========================================================================

.hook-text-lead() {}

.hook-text-meta() {}


// Size modifiers
// ========================================================================

.hook-text-small() {}

.hook-text-large() {}


// Background modifier
// ========================================================================

.hook-text-background() when not (@internal-text-background-color-gradient = ~'') {
    background-image: @internal-text-background-color-gradient;
}


// Miscellaneous
// ========================================================================

.hook-text-misc() {}


// Inverse
// ========================================================================

.hook-inverse-text-lead() {}
.hook-inverse-text-meta() {}
