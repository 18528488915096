// Name:            Slider
// Description:     Component to create horizontal sliders
//
// Component:       `uk-slider`
//
// Sub-objects:     `uk-slider-container`
//                  `uk-slider-items`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

@slider-container-margin-top:                       -11px;
@slider-container-margin-bottom:                    -39px;
@slider-container-margin-left:                      -25px;
@slider-container-margin-right:                     -25px;


/* ========================================================================
   Component: Slider
 ========================================================================== */

/*
 * 1. Prevent tab highlighting on iOS.
 */

.uk-slider {
    /* 1 */
    -webkit-tap-highlight-color: transparent;
    .hook-slider();
}


/* Container
 ========================================================================== */

/*
 * 1. Clip child elements
 * 2. Prevent accidental scrolling through elements in slide getting focused
 */

.uk-slider-container {
    /* 1 */
    overflow: hidden;
    /* 2 */
    overflow: clip;
}

/*
 * Widen container to prevent box-shadows from clipping, `large-box-shadow`
 */

.uk-slider-container-offset {
    margin: @slider-container-margin-top @slider-container-margin-right @slider-container-margin-bottom @slider-container-margin-left;
    padding: (@slider-container-margin-top * -1) (@slider-container-margin-right * -1) (@slider-container-margin-bottom * -1) (@slider-container-margin-left * -1);
}

/* Items
 ========================================================================== */

/*
 * 1. Optimize animation
 * 2. Create a containing block. In Safari it's neither created by `transform` nor `will-change`.
 * 3. Disable horizontal panning gestures
 */

.uk-slider-items {
    /* 1 */
    will-change: transform;
    /* 2 */
    position: relative;
    /* 3 */
    touch-action: pan-y;
}

/*
 * 1. Reset list style without interfering with grid
 * 2. Prevent displaying the callout information on iOS.
 */

.uk-slider-items:not(.uk-grid) {
    display: flex;
    /* 1 */
    margin: 0;
    padding: 0;
    list-style: none;
    /* 2 */
    -webkit-touch-callout: none;
}

.uk-slider-items.uk-grid { flex-wrap: nowrap; }


/* Item
 ========================================================================== */

/*
 * 1. Let items take content dimensions (0 0 auto)
 *    `max-width` needed to keep image responsiveness and prevent content overflow
 * 2. Create position context
 */

.uk-slider-items > * {
    /* 1 */
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    /* 2 */
    position: relative;
}


// Hooks
// ========================================================================

.hook-slider-misc();

.hook-slider() {}
.hook-slider-misc() {}
