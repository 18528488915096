//
// Component: Divider
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@internal-divider-icon-line-left-border-gradient:   ~'';

@internal-divider-icon-line-right-border-gradient:  ~'';

@internal-divider-small-border-gradient:            ~'';
@internal-divider-small-image:                      ~'';

@internal-divider-vertical-border-gradient:         ~'';


// Icon
// ========================================================================

.hook-divider-icon() {}

.hook-divider-icon-line() {
    // Fix Safari setting 3px `border-width` if `border-image` is used.
    border-width: 0 0 @divider-icon-line-border-width 0;
}

.hook-divider-icon-line-left() when not (@internal-divider-icon-line-left-border-gradient = ~'') {
    border-image: @internal-divider-icon-line-left-border-gradient;
    border-image-slice: 1;
}

.hook-divider-icon-line-right() when not (@internal-divider-icon-line-right-border-gradient = ~'') {
    border-image: @internal-divider-icon-line-right-border-gradient;
    border-image-slice: 1;
}


// Small
// ========================================================================

.hook-divider-small() {}

.hook-divider-small() when not (@internal-divider-small-border-gradient = ~'') and (@internal-divider-small-image = ~'') {
    border-image: @internal-divider-small-border-gradient;
    border-image-slice: 1;
    // Fix Safari setting 3px `border-width` if `border-image` is used.
    border-width: @divider-small-border-width 0 0 0;
}

.hook-divider-small() when not (@internal-divider-small-image = ~'') and (@internal-divider-small-border-gradient = ~'') {
    .svg-fill(@internal-divider-small-image, "#000", @divider-small-border);
    border-color: transparent;
}


// Vertical
// ========================================================================

.hook-divider-vertical() when not (@internal-divider-vertical-border-gradient = ~'') {
    border-image: @internal-divider-vertical-border-gradient;
    border-image-slice: 1;
    // Fix Safari setting 3px `border-width` if `border-image` is used.
    border-width: 0 0 0 @divider-vertical-border-width;
}


// Miscellaneous
// ========================================================================

.hook-divider-misc() {}


// Inverse
// ========================================================================

.hook-inverse-divider-icon() {}
.hook-inverse-divider-icon-line() when not (@internal-divider-icon-line-left-border-gradient = ~''),
                                       not (@internal-divider-icon-line-right-border-gradient = ~'') {
    border-image: none;
}

.hook-inverse-divider-small() when not (@internal-divider-small-border-gradient = ~'') and (@internal-divider-small-image = ~'') {
    border-image: none;
}

.hook-inverse-divider-small() when not (@internal-divider-small-image = ~'') and (@internal-divider-small-border-gradient = ~'') {
    .svg-fill(@internal-divider-small-image, "#000", @inverse-divider-small-border);
    border-color: transparent;
}

.hook-inverse-divider-vertical() when not (@internal-divider-vertical-border-gradient = ~'') {
    border-image: none;
}
