//
// Component:       Mixin
// Description:     Defines mixins which are used across all components
//
// ========================================================================


// SVG
// ========================================================================

.svg-fill(@src, @color-default, @color-new, @property: background-image) {

    @escape-color-default: escape(@color-default);
    @escape-color-new: escape("@{color-new}");

    @data-uri: data-uri('image/svg+xml;charset=UTF-8', "@{src}");
    @replace-src: replace("@{data-uri}", "@{escape-color-default}", "@{escape-color-new}", "g");

    @{property}: e(@replace-src);
}
