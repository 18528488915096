//
// Component: Accordion
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@accordion-title-padding-vertical:             0;
@accordion-title-padding-horizontal:           0;
@accordion-title-background:                   transparent;

@accordion-icon-margin-left:                   10px;
@accordion-icon-color:                         @global-color;

@internal-accordion-icon-close-image:          "../../images/backgrounds/accordion-close.svg";
@internal-accordion-icon-open-image:           "../../images/backgrounds/accordion-open.svg";


// Component
// ========================================================================

.hook-accordion() {}


// Item
// ========================================================================

.hook-accordion-item() {}


// Title
// ========================================================================

.hook-accordion-title() {
    overflow: hidden;

    &::before {
        content: "";
        width: (@accordion-title-line-height * 1em);
        height: (@accordion-title-line-height * 1em);
        margin-left: @accordion-icon-margin-left;
        float: right;
        .svg-fill(@internal-accordion-icon-close-image, "#000", @accordion-icon-color);
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .uk-open > &::before { .svg-fill(@internal-accordion-icon-open-image, "#000", @accordion-icon-color); }

}

.hook-accordion-title() when not (@accordion-title-padding-vertical = 0), not (@accordion-title-padding-horizontal = 0) {
    padding: @accordion-title-padding-vertical @accordion-title-padding-horizontal;
}

.hook-accordion-title() when not (@accordion-title-background = transparent) {
    background: @accordion-title-background;
}

.hook-accordion-title() when not (@base-link-text-decoration = none) {
    text-decoration: none;
}

.hook-accordion-title-hover() {}


// Content
// ========================================================================

.hook-accordion-content() {}


// Miscellaneous
// ========================================================================

.hook-accordion-misc() {}


// Inverse
// ========================================================================

@inverse-accordion-title-background:           transparent;
@inverse-accordion-icon-color:                 @inverse-global-color;

.hook-inverse-accordion-item() {}

.hook-inverse-accordion-title() when not (@inverse-accordion-title-background = transparent) {
    background-color: @inverse-accordion-title-background;
}
.hook-inverse-accordion-title-hover() {}

.hook-inverse() {

    .uk-accordion-title::before { .svg-fill(@internal-accordion-icon-close-image, "#000", @inverse-accordion-icon-color); }

    .uk-open > .uk-accordion-title::before { .svg-fill(@internal-accordion-icon-open-image, "#000", @inverse-accordion-icon-color); }

}
