//
// Component: Search
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@search-default-border-mode:                        ~'';
@search-default-border-width:                       0;
@search-default-border:                             transparent;
@search-default-focus-border:                       transparent;

@search-navbar-border-mode:                         ~'';
@search-navbar-border-width:                        0;
@search-navbar-border:                              transparent;
@search-navbar-focus-border:                        transparent;

@search-large-border-mode:                          ~'';
@search-large-border-width:                         0;
@search-large-border:                               transparent;
@search-large-focus-border:                         transparent;


// Component
// ========================================================================

.hook-search-input() {}


// Icon
// ========================================================================

.hook-search-icon() {}


// Default modifiers
// ========================================================================

.hook-search-default-input() when not (@search-default-border-width = 0) {
    border@{search-default-border-mode}: @search-default-border-width solid @search-default-border;
}

.hook-search-default-input-focus() when not (@search-default-border-width = 0) {
    border@{search-default-border-mode}-color: @search-default-focus-border;
}


// Navbar modifiers
// ========================================================================

.hook-search-navbar-input() when not (@search-navbar-border-width = 0) {
    border@{search-navbar-border-mode}: @search-navbar-border-width solid @search-navbar-border;
}

.hook-search-navbar-input-focus() when not (@search-navbar-border-width = 0) {
    border@{search-navbar-border-mode}-color: @search-navbar-focus-border;
}


// Large modifiers
// ========================================================================

.hook-search-large-input() when not (@search-large-border-width = 0) {
    border@{search-large-border-mode}: @search-large-border-width solid @search-large-border;
}

.hook-search-large-input-focus() when not (@search-large-border-width = 0) {
    border@{search-large-border-mode}-color: @search-large-focus-border;
}


// Toggle
// ========================================================================

.hook-search-toggle() {}

.hook-search-toggle-hover() {}


// Miscellaneous
// ========================================================================

.hook-search-misc() {}


// Inverse
// ========================================================================

@inverse-search-default-border:                             transparent;
@inverse-search-default-focus-border:                       transparent;

@inverse-search-navbar-border:                              transparent;
@inverse-search-navbar-focus-border:                        transparent;

@inverse-search-large-border:                               transparent;
@inverse-search-large-focus-border:                         transparent;

.hook-inverse-search-default-input() when not (@search-default-border-width = 0) {
    border@{search-default-border-mode}-color: @inverse-search-default-border;
}
.hook-inverse-search-default-input-focus() when not (@search-default-border-width = 0) and not (@inverse-search-default-focus-border =  @inverse-search-default-border) {
    border@{search-default-border-mode}-color: @inverse-search-default-focus-border;
}

.hook-inverse-search-navbar-input() when not (@search-navbar-border-width = 0) {
    border@{search-navbar-border-mode}-color: @inverse-search-navbar-border;
}
.hook-inverse-search-navbar-input-focus() when not (@search-navbar-border-width = 0) and not (@inverse-search-navbar-focus-border =  @inverse-search-navbar-border) {
    border@{search-navbar-border-mode}-color: @inverse-search-navbar-focus-border;
}

.hook-inverse-search-large-input() when not (@search-large-border-width = 0) {
    border@{search-large-border-mode}-color: @inverse-search-large-border;
}
.hook-inverse-search-large-input-focus() when not (@search-large-border-width = 0) and not (@inverse-search-large-focus-border =  @inverse-search-large-border) {
    border@{search-large-border-mode}-color: @inverse-search-large-focus-border;
}

.hook-inverse-search-toggle() {}
.hook-inverse-search-toggle-hover() {}
