// Name:            Form Range
// Description:     Styles for the range input type
//
// Component:       `uk-range`
//
// ========================================================================


// Variables
// ========================================================================

@form-range-track-height:                       3px;
@form-range-track-background:                   darken(@global-muted-background, 5%);
@form-range-track-focus-background:             darken(@form-range-track-background, 5%);

@form-range-thumb-height:                       15px;
@form-range-thumb-width:                        @form-range-thumb-height;
@form-range-thumb-border-radius:                500px;
@form-range-thumb-background:                   @global-color;


/* ========================================================================
   Component: Form Range
 ========================================================================== */

/*
 * 1. Remove default style.
 * 2. Define consistent box sizing.
 * 3. Remove `margin` in all browsers.
 * 4. Align to the center of the line box.
 * 5. Prevent content overflow if a fixed width is used.
 * 6. Take the full width.
 * 7. Remove white background in Chrome.
 */

.uk-range {
    /* 1 */
    -webkit-appearance: none;
    /* 2 */
    box-sizing: border-box;
    /* 3 */
    margin: 0;
    /* 4 */
    vertical-align: middle;
    /* 5 */
    max-width: 100%;
    /* 6 */
    width: 100%;
    /* 7 */
    background: transparent;
    .hook-form-range();
}

/* Focus */
.uk-range:focus { outline: none; }
.uk-range::-moz-focus-outer { border: none; }

/*
 * Improves consistency of cursor style for clickable elements
 */

.uk-range:not(:disabled)::-webkit-slider-thumb { cursor: pointer; }
.uk-range:not(:disabled)::-moz-range-thumb { cursor: pointer; }


/*
 * Track
 * 1. Safari doesn't have a focus state. Using active instead.
 */

/* Webkit */
.uk-range::-webkit-slider-runnable-track {
    height: @form-range-track-height;
    background: @form-range-track-background;
    .hook-form-range-track();
}

.uk-range:focus::-webkit-slider-runnable-track,
/* 1 */
.uk-range:active::-webkit-slider-runnable-track {
    background: @form-range-track-focus-background;
    .hook-form-range-track-focus();
}

/* Firefox */
.uk-range::-moz-range-track {
    height: @form-range-track-height;
    background: @form-range-track-background;
    .hook-form-range-track();
}

.uk-range:focus::-moz-range-track {
    background: @form-range-track-focus-background;
    .hook-form-range-track-focus();
}

/*
 * Thumb
 * 1. Reset
 * 2. Style
 */

/* Webkit */
.uk-range::-webkit-slider-thumb {
    /* 1 */
    -webkit-appearance: none;
    margin-top: (floor((@form-range-thumb-height / 2)) * -1);
    /* 2 */
    height: @form-range-thumb-height;
    width: @form-range-thumb-width;
    border-radius: @form-range-thumb-border-radius;
    background: @form-range-thumb-background;
    .hook-form-range-thumb();
}

/* Firefox */
.uk-range::-moz-range-thumb {
    /* 1 */
    border: none;
    /* 2 */
    height: @form-range-thumb-height;
    width: @form-range-thumb-width;
    margin-top: (floor((@form-range-thumb-height / 2)) * -1);
    border-radius: @form-range-thumb-border-radius;
    background: @form-range-thumb-background;
    .hook-form-range-thumb();
}


// Hooks
// ========================================================================

.hook-form-range-misc();

.hook-form-range() {}
.hook-form-range-track() {}
.hook-form-range-track-focus() {}
.hook-form-range-thumb() {}
.hook-form-range-misc() {}
