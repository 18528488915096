//
// Component: Form Range
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@form-range-track-border-radius:                500px;


// Component
// ========================================================================

.hook-form-range() {}


// Thumb
// ========================================================================

.hook-form-range-thumb() {}


// Track
// ========================================================================

.hook-form-range-track() when not (@form-range-track-border-radius = 0) {
    border-radius: @form-range-track-border-radius;
}

.hook-form-range-track-focus() {}


// Miscellaneous
// ========================================================================

.hook-form-range-misc() {}