//
// Component: Button
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@button-border-radius:                          0;

@button-small-border-radius:                    0;
@button-large-border-radius:                    0;


// Component
// ========================================================================

.hook-button() when not (@button-border-radius = 0) {
    border-radius: @button-border-radius;
}

.hook-button-hover() {}

.hook-button-active() {}


// Style modifiers
// ========================================================================

.hook-button-default() {}

.hook-button-default-hover() {}

.hook-button-default-active() {}

//
// Primary
//

.hook-button-primary() {}

.hook-button-primary-hover() {}

.hook-button-primary-active() {}

//
// Secondary
//

.hook-button-secondary() {}

.hook-button-secondary-hover() {}

.hook-button-secondary-active() {}

//
// Danger
//

.hook-button-danger() {}

.hook-button-danger-hover() {}

.hook-button-danger-active() {}


// Disabled
// ========================================================================

.hook-button-disabled() {}


// Size modifiers
// ========================================================================

.hook-button-small() when not (@button-small-border-radius = 0) {
    border-radius: @button-small-border-radius;
}

.hook-button-large() when not (@button-large-border-radius = 0) {
    border-radius: @button-large-border-radius;
}


// Text modifier
// ========================================================================

.hook-button-text() when not (@button-border-radius = 0), not (@button-small-border-radius = 0), not (@button-large-border-radius = 0) {
    border-radius: 0;
}

.hook-button-text-hover() {}

.hook-button-text-disabled() {}


// Link modifier
// ========================================================================

.hook-button-link() when not (@button-border-radius = 0), not (@button-small-border-radius = 0), not (@button-large-border-radius = 0) {
    border-radius: 0;
}


// Miscellaneous
// ========================================================================

.hook-button-misc() when not (@button-border-radius = 0) {

    /* Group
     ========================================================================== */

    /*
     * Reset border-radius
     */

    .uk-button-group > .uk-button:not(:first-child):not(:last-child),
    .uk-button-group > div:not(:first-child):not(:last-child) .uk-button { border-radius: 0; }

    .uk-button-group > .uk-button:first-child,
    .uk-button-group > div:first-child .uk-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .uk-button-group > .uk-button:last-child,
    .uk-button-group > div:last-child .uk-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

}


// Inverse
// ========================================================================

.hook-inverse-button-default() {}
.hook-inverse-button-default-hover() {}
.hook-inverse-button-default-active() {}

.hook-inverse-button-primary() {}
.hook-inverse-button-primary-hover() {}
.hook-inverse-button-primary-active() {}

.hook-inverse-button-secondary() {}
.hook-inverse-button-secondary-hover() {}
.hook-inverse-button-secondary-active() {}

.hook-inverse-button-text() {}
.hook-inverse-button-text-hover() {}
.hook-inverse-button-text-disabled() {}

.hook-inverse-button-link() {}