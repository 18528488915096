//***
// These are the hacks needed to pull TS over from the old MDB Bootstrap based components.
// Eliminate with prejudice when possible
//***

//
// Override and new Variables
//
//
@card-danger-background: red;
@card-danger-color: @card-primary-color;
@card-danger-title-color: @card-primary-title-color;
@card-danger-hover-background: @card-primary-hover-background;

// make primary nav bolder not larger
@nav-primary-font-size: @global-font-size;
@nav-primary-item-color: @global-primary-background;
@nav-primary-item-hover-color: @global-muted-color;
.uk-nav-primary {
  font-weight: 600;
}

// Site Design

// Crazy apply uk-active to li from vue's router-link magic
// Need uk-active on the li above the a
.uk-navbar-nav li:has(> a.uk-active) {
  font-weight: bolder;
}

.uk-pagination > .uk-active > * {
  //border: 2px solid green;
  outline: 1px solid @global-primary-background;
}

.uk-logo {
  img {
    min-width: 87px;
    min-height: 25px;
    max-width: 175px;
    max-height: 50px;
  }
}

.uk-logo.uk-logo-large {
  img {
    max-width: 150px;
    max-height: 150px;
  }
}

// padding the button for new line height;
.uk-button {
  padding-inline: 14px;
  // padding-top: 4px;
  border-radius: 0;
  // outline: 2px solid @global-primary-background;
  // outline-offset: 3px;
  // padding: .55em;
}

.uk-button-default {
  border: 1px solid @global-primary-background;
  color: @global-primary-background;
}

.uk-button-success {
  background-color: rgb(26, 200, 26);
  color: white;
}

.uk-button-success:hover,
.uk-button-success:focus {
  background-color: @global-background;
  color: @global-primary-background;
  outline: 2px solid rgb(26, 200, 26) !important;
}

.main-menu li .uk-icon {
  margin-top: -6px;
}

#offcanvasNav .uk-nav-sub {
  font-size: 14px;
}

// Global Footer Styles
.footerCorpLogo {
  width: 100px;
}

// I think these need to become button hooks
.pantone-product-select:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
}

.pantone-product-select.active {
  background-color: rgba(119, 59, 150, 0.3);
}

// Portal Page Layout
.project-library {
  min-height: 768px;
  max-height: 960px;
  overflow-y: scroll;
  overflow-x: visible;
  word-wrap: break-word;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px @global-border;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: @global-primary-background;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: @global-link-hover-color;
  }

  border-right-width: 2px;
  border-right-color: @global-border;
  border-right-style: solid;
}

// Product icons

.product-navigation {
  z-index: 10;
  height: 95px;

  .uk-first-column {
    padding-left: 50px
  }
}

.product-selection {
  padding-top: .1em;

  &:hover {
    cursor: pointer;
  }
}

.product-icon {
  width: 45px;
  height: 38px;
  fill: @global-emphasis-color;

  &:hover {
    fill: @global-background;

    .iconFill {
      fill: @global-primary-background;

    }
  }

  .iconFill {
    fill: @global-muted-background;
  }
}

.uk-active {
  .product-icon {
    fill: @global-background;

    .iconFill {
      //fill: @global-warning-background;
      fill: @global-primary-background;
    }
  }
}

//Image library cards
.uk-card-danger {
  background-color: @card-danger-background;
  color: @card-danger-color;
  //.hook-card-danger();
}

.uk-card-danger .uk-card-title {
  color: @card-danger-title-color;
  //.hook-card-danger-title();
}

.uk-card-danger.uk-card-hover:hover {
  background-color: @card-danger-hover-background;
  //.hook-card-danger-hover();
}

// checkout
.billing-message-container {
  // .uk-sticky {
  position: sticky;
  top: 80px !important;
  // offset: 80px;
  z-index: 100000;
  // }
}

.saving-container {
  position: fixed;
  top: -50%;
  left: -50%;
  height: 200%;
  width: 200%;
  background: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: @global-muted-color;
  z-index: 501;
}

//VuePortal Page

.gridbackground {
  background-size: 15px 15px;
  background-image: linear-gradient(0deg,
  rgb(200, 200, 200),
  rgb(200, 200, 200) 0.5px,
  transparent 1px,
  transparent 20px),
  linear-gradient(-90deg,
  rgb(200, 200, 200),
  rgb(200, 200, 200) 0.5px,
  transparent 1px,
  transparent 20px);
  z-index: 2;
}

.filter-controls {

  border-right: 3px solid @global-border;

  li.uk-active {
    & > a {
      .uk-text-warning;
    }
  }
}

.instruction-pane {
  z-index: 20;
}

.error-button {
  z-index: 20;
}

.product-preview {
  z-index: 20;
}

/*svg ProductPreview position command sizing*/
.product-preview {
  position: relative;
  box-sizing: content-box;
  width: 50vw;
  height: 50vh;
  font-size: 6%;
  margin-top: 1vh;
  margin-left: 1vw;
  /*border: 5px solid fuchsia;*/
  /*transform: translate(5%, 5%);*/
}

.product-preview-svg {
  height: 100%;
  width: 100%;
}

//svg sheet gang-up preview
.layout-preview {
  width: 41vh;
  height: 45vh;
  bottom: 25px;
  right: 15px;
}

.sheet-pager {
  text-align: center;
  /*position: relative;*/
  position: absolute;
  z-index: 15;
  right: 0;
  bottom: 22vh;
}

.sheet-pager.tallbox-pager {
  bottom: 42vh;
}

.product-sheet-preview {
  bottom: 5px;
  right: 0;
  z-index: 20;
  box-sizing: content-box;
  border: 5px solid @global-primary-background;
}

.sheet-preview-svg {
  height: 100%;
}

//svg Hole Previews
.svgHole,
.svgBox,
.svgLine,
.svgDLine {
  /*fill: transparent;*/
  //fill: #f2f2f2;
  //fill-opacity: .30;
  /*fill-opacity: 1;*/
  //stroke: #773b96;
  stroke-width: 1px;
  stroke: @base-pre-color;
  //fill: @overlay-primary-background;
  fill: @overlay-default-background;
  vector-effect: non-scaling-stroke; /* I recommend this since it keeps the stroke size consistent. */
  //.uk-background-blend-screen;
  .uk-background-blend-exclusion;
}

.svgHole {
  fill: none;
}

.svgText {
  font-family: @base-code-font-family;
  fill: @global-success-background;
}

.svgC2Etext {
  font-family: @base-code-font-family;
  font-style: italic;
  fill: @base-pre-color;
}

//Architextures page
.arch-frame {
  width: 100%;
  height: 1024px;
}

.folder-control-list .folder-text {
  padding-top: 6px;
}

.patn-library-alert-success {
  border: none;

  & > * {
    color: rgb(26, 200, 26);
    padding-top: 7px;
  }
}

.patn-library-alert-danger {
  border: none;

  & > * {
    color: #e44e56;
    padding-top: 7px;
  }
}

.patn-order-section {
  // max-height: 480px!important;
  min-width: 350px;
}

.uk-media-top {
  overflow: hidden;
}

.image-container {
  width: fit-content;
}

.patn-library-card {
  width: fit-content;

  .patn-background-image {
    // width: 660px;
    // min-width: 350px;
    // max-width: 480px;
    // // height: 600px;
    // min-height: 350px;
    // max-height: 480px;
    height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    background-image: url(/public/images/pattern_background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: clip;

  }

  @media (max-width: 859px) {
    margin-left: auto;
    margin-right: auto;
  }

  .patn-background-color {
    width: auto;
    height: auto;
    aspect-ratio: 1/1;
    background-color: @global-secondary-background;
    background-repeat: no-repeat;
    background-size: cover;
  }

  img.patn-img {
    //width: auto;
    // max-width: 480px;
    //height: 600px;
    //height: auto;
    // max-height: 480px;
  }
}

.patn-library-card.patn-library-card-hover:hover {
  .patn-img {
    // transform: translateY(-50%);
    // transform: translateX(-50%);
    left: -30%;
    //display: none;
  }
}

//.patn-library-card.order-library-card-hover {
//  .patn-img {
//    transform: translateX(-30%);
//    //display: none;
//  }
//}
//
.patn-img {
  transition: left 0.2s ease-in-out;
}

.uk-modal {
  .patn-library-card {
    .patn-background-image {
      // width: 660px;
      min-width: 350px;
      max-width: 480px;
      // // height: 600px;
      min-height: 350px;
      max-height: 480px;
      // height: 100%;
      // width: 100%;
      aspect-ratio: 1/1;
      background-image: url(/public/images/pattern_background.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      overflow: clip;
    }

    .patn-background-color {
      max-width: 480;
      max-height: 480;
      aspect-ratio: 1/1;
      background-color: @global-secondary-background;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img.patn-img {
      // width: auto;
      max-width: 480px;
      //height: 600px;
      // height: auto;
      max-height: 480px;
    }
  }
}

.loading-animation-container {
  position: relative;
}

.animation-img {
  position: absolute;
  top: 130px;
  height: auto;
  width: 220px;
}

.loading-animation {
  height: 300px;
  min-width: 300px;
  position: relative;
}

.loading-txt {
  position: absolute;
  bottom: 0px;
  font-size: 40px;
  font-weight: bold;
  background-color: @global-background;
}

.brand-section {
  .brand-grid {
    .brand {
      .uk-flex;
      .uk-flex-center;
      margin-bottom: 2em;
    }

    img {
      max-height: 150px;
      width: auto;
    }
  }
}


// tile menu

.cat-tile {
  box-sizing: border-box;
  position: relative;
  // width: 100%;
  background-size: cover;
  aspect-ratio: 4/3 !important;
  background-position: center;
  padding-bottom: 30px;
  filter: grayscale(50%);
  transition: filter 0.2s ease-in-out, background-color 0.2s ease-in-out, outline 0.2s ease-in-out;
  // box-shadow: 0 0 5 0px rgba(100, 100, 100,0.8);
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.2);
  // box-sizing: border-box!important;

}

.tile-container {
  box-sizing: border-box !important;

  .uk-card-default {
    width: calc(33% - 6px);
    margin-inline: 5px;
    background-color: transparent;
    @media (max-width: 1290px) {
      width: calc(50% - 10px);
    }
    @media (max-width: 650px) {
      width: calc(100% - 10px);
    }
  }
}

.cat-tile:hover {
  outline: 2px solid @global-primary-background;
  background-color: @global-background;
  outline-offset: 7px;
  overflow: hidden;
  filter: grayscale(0%);
}

.brand-logo-container:hover {
  padding-top: 100px;
}

.cat-tile:hover + .tile-title,
.cat-tile:hover + .subtile-title {
  // height: 10%;
  width: 95%;
  height: 25%;
  font-size: 20px;
  top: 15.75%;
  transform: -20%, -50%;
}

.cat-tile:hover + .subtile-title {
  background-color: @global-primary-background;
  color: @global-background;
  text-shadow: 0 0 0 0 rgba(0, 0, 0, 0.0);
  padding-bottom: 0px;
}

.cat-tile:hover > .tile-nav-icon {
  color: @global-primary-background;
}

.tile-title {
  --delay: 0.3s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 90%;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: @global-primary-background;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 30px;
  font-weight: 700;
  pointer-events: none;
  box-sizing: border-box;
  padding-inline: 50px;
  transition: width 0.2s ease-in-out,
  height var(--delay) ease-in-out, font-size var(--delay) ease-in-out,
  top var(--delay) ease-in-out, left var(--delay) ease-in-out,
  transform var(--delay) ease-in-out;
}

.subtile-title {
  --delay: 0.3s;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: @global-primary-background;
  background-color: rgba(255, 255, 255, 0.0);
  text-shadow: 0px 0px 5px @global-background;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
  box-sizing: border-box;
  padding-inline: 50px;
  padding-bottom: 20px;
  transition: width 0.2s ease-in-out,
  height var(--delay) ease-in-out, font-size var(--delay) ease-in-out,
  top var(--delay) ease-in-out, left var(--delay) ease-in-out,
  transform var(--delay) ease-in-out, background-color var(--delay) ease-in-out;
}

.brand-logo-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  transition: padding-top 0.3s ease-in-out;
}

.brand-logo {
  --delay: 0.3s;
  background-position: center center;
  width: 80%;
  height: 80%;
  bottom: 100;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, 0);
  transition: width var(--delay) ease-in-out,
  height var(--delay) ease-in-out,
  margin-left var(--delay) ease-in-out,
  transform var(--delay) ease-in-out;
}

.cat-tile:hover > .brand-logo {
  width: 30%;
  height: 30%;
  margin-left: 0;
  transform: translate(5px, -10px);
}

.cat-tile:hover > .substrate-info {
  width: 80%;
  padding-right: 20px;
  color: black;
}

.tile-nav-icon {
  position: absolute;
  background-size: contain;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

.nick-name {
  .uk-invisible;
}

.uk-button.contact-button {
  line-height: 1;
  padding: 1em;
}

.raised-search-input:focus {
  //border-radius: 20px;
  //padding-left: 1em;
  box-shadow: .25em .25em .25em @global-color;
  //border: none;
}

.lk-zoom:hover {
  cursor: zoom-in;
}

.lk-pointer:hover {
  cursor: pointer;
}

// Animation Fixes
// Yarn's less compiler did not handle:
// [class*="uk-animation-"] { animation: @animation-duration ease-out both; }

//@animation-duration: .5s;
//@animation-fade-duration: .5s;
//@animation-kenburns-duration: 2.0s;
/*
 * Fade
 */

.uk-animation-fade {
  animation-name: uk-fade;
  animation-duration: @animation-fade-duration;
  animation-timing-function: linear;
}

/*
 * Slide
 */

.uk-animation-slide-top {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-top; }
.uk-animation-slide-bottom {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-bottom; }
.uk-animation-slide-left {
  animation: @animation-duration ease-out both;
  animation-name: uk-fade, uk-slide-left;
}
.uk-animation-slide-right { animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-right; }

/*
 * Scale
 */

.uk-animation-scale-up {
  animation: @animation-duration ease-out both;
  animation-name: uk-fade, uk-scale-up;
}
.uk-animation-scale-down {
  animation: @animation-duration ease-out both;
  animation-name: uk-fade, uk-scale-down;
}

/*
 * Slide Small
 */

.uk-animation-slide-top-small {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-top-small; }
.uk-animation-slide-bottom-small {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-bottom-small; }
.uk-animation-slide-left-small {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-left-small; }
.uk-animation-slide-right-small {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-right-small; }

/*
 * Slide Medium
 */

.uk-animation-slide-top-medium {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-top-medium; }
.uk-animation-slide-bottom-medium {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-bottom-medium; }
.uk-animation-slide-left-medium {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-left-medium; }
.uk-animation-slide-right-medium {animation: @animation-duration ease-out both; animation-name: uk-fade, uk-slide-right-medium; }

/*
 * Kenburns
 */

.uk-animation-kenburns {
  animation-duration: @animation-kenburns-duration;
  animation-name: uk-kenburns;
}

/*
 * Shake
 */

.uk-animation-shake {animation: @animation-duration ease-out both; animation-name: uk-shake; }
