// Name:            Overlay
// Description:     Component to create content areas overlaying an image
//
// Component:       `uk-overlay`
//
// Adopted:         `uk-overlay-icon`
//
// Modifier:        `uk-overlay-default`
//                  `uk-overlay-primary`
//
// ========================================================================


// Variables
// ========================================================================

@overlay-padding-horizontal:                    @global-gutter;
@overlay-padding-vertical:                      @global-gutter;

@overlay-default-background:                    fade(@global-background, 80%);

@overlay-primary-background:                    fade(@global-secondary-background, 80%);
@overlay-primary-color-mode:                    light;


/* ========================================================================
   Component: Overlay
 ========================================================================== */

.uk-overlay {
    padding: @overlay-padding-vertical @overlay-padding-horizontal;
    .hook-overlay();
}

/*
 * Remove margin from the last-child
 */

.uk-overlay > :last-child { margin-bottom: 0; }


/* Icon
 ========================================================================== */

.uk-overlay-icon {
    .hook-overlay-icon();
}


/* Style modifiers
 ========================================================================== */

/*
 * Default
 */

.uk-overlay-default {
    background: @overlay-default-background;
    .hook-overlay-default();
}

/*
 * Primary
 */

.uk-overlay-primary {
    background: @overlay-primary-background;
    .hook-overlay-primary();
}

// Color Mode
.uk-overlay-primary:extend(.uk-light all) when (@overlay-primary-color-mode = light) {}
.uk-overlay-primary:extend(.uk-dark all) when (@overlay-primary-color-mode = dark) {}


// Hooks
// ========================================================================

.hook-overlay-misc();

.hook-overlay() {}
.hook-overlay-icon() {}
.hook-overlay-default() {}
.hook-overlay-primary() {}
.hook-overlay-misc() {}
