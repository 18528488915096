// Name:            Placeholder
// Description:     Component to create placeholder boxes
//
// Component:       `uk-placeholder`
//
// ========================================================================


// Variables
// ========================================================================

@placeholder-margin-vertical:                   @global-margin;
@placeholder-padding-vertical:                  @global-gutter;
@placeholder-padding-horizontal:                @global-gutter;
@placeholder-background:                        @global-muted-background;


/* ========================================================================
   Component: Placeholder
 ========================================================================== */

.uk-placeholder {
    margin-bottom: @placeholder-margin-vertical;
    padding: @placeholder-padding-vertical @placeholder-padding-horizontal;
    background: @placeholder-background;
    .hook-placeholder();
}

/* Add margin if adjacent element */
* + .uk-placeholder { margin-top: @placeholder-margin-vertical; }

/*
 * Remove margin from the last-child
 */

.uk-placeholder > :last-child { margin-bottom: 0; }


// Hooks
// ========================================================================

.hook-placeholder-misc();

.hook-placeholder() {}
.hook-placeholder-misc() {}
