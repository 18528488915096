// Name:            Badge
// Description:     Component to create notification badges
//
// Component:       `uk-badge`
//
// ========================================================================


// Variables
// ========================================================================

@badge-size:                                    18px;
@badge-padding-vertical:                        0;
@badge-padding-horizontal:                      5px;
@badge-border-radius:                           500px;
@badge-background:                              @global-primary-background;
@badge-color:                                   @global-inverse-color;
@badge-font-size:                               11px;


/* ========================================================================
   Component: Badge
 ========================================================================== */

/*
 * 1. Style
 * 2. Center child vertically and horizontally
 */

.uk-badge {
    box-sizing: border-box;
    min-width: @badge-size;
    height: @badge-size;
    padding: @badge-padding-vertical @badge-padding-horizontal;
    border-radius: @badge-border-radius;
    vertical-align: middle;
    /* 1 */
    background: @badge-background;
    color: @badge-color !important;
    font-size: @badge-font-size;
    /* 2 */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    .hook-badge();
}

/*
 * Required for `a`
 */

.uk-badge:hover {
    text-decoration: none;
    .hook-badge-hover();
}


// Hooks
// ========================================================================

.hook-badge-misc();

.hook-badge() {}
.hook-badge-hover() {}
.hook-badge-misc() {}


// Inverse
// ========================================================================

@inverse-badge-background:                     @inverse-global-primary-background;
@inverse-badge-color:                          @inverse-global-inverse-color;

.hook-inverse() {

    .uk-badge {
        background-color: @inverse-badge-background;
        color: @inverse-badge-color !important;
        .hook-inverse-badge();
    }

    .uk-badge:hover {
        .hook-inverse-badge-hover();
    }

}

.hook-inverse-badge() {}
.hook-inverse-badge-hover() {}
