// Name:            Drop
// Description:     Component to position any element next to any other element.
//
// Component:       `uk-drop`
//
// Sub-objects:     `uk-drop-parent-icon`
//
// Modifiers:       `uk-drop-stack`
//                  `uk-drop-grid`
//
// States:          `uk-open`
//
// Uses:            Animation
//
// ========================================================================


// Variables
// ========================================================================

@drop-z-index:                              @global-z-index + 20;
@drop-margin:                               @global-margin;
@drop-viewport-margin:                      15px;
@drop-width:                                300px;

@drop-parent-icon-margin-left:              0.25em;


/* ========================================================================
   Component: Drop
 ========================================================================== */

/*
 * 1. Hide by default
 * 2. Set position
 * 3. Set a default width
 */

.uk-drop {
    /* 1 */
    display: none;
    /* 2 */
    position: absolute;
    z-index: @drop-z-index;
    --uk-position-offset: @drop-margin;
    --uk-position-viewport-offset: @drop-viewport-margin;
    /* 3 */
    box-sizing: border-box;
    width: @drop-width;
}

/* Show */
.uk-drop.uk-open { display: block; }


/* Grid modifiers
 ========================================================================== */

.uk-drop-stack .uk-drop-grid > * { width: 100% !important; }


/* Parent icon
 ========================================================================== */

.uk-drop-parent-icon {
    margin-left: @drop-parent-icon-margin-left;
    transition: transform 0.3s ease-out;
}

[aria-expanded="true"] > .uk-drop-parent-icon { transform: rotateX(180deg); }


// Hooks
// ========================================================================

.hook-drop-misc();

.hook-drop-misc() {}
