//
// Component: Iconnav
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@iconnav-item-font-size:                         @global-small-font-size;


// Component
// ========================================================================

.hook-iconnav() {}

.hook-iconnav-item() { font-size: @iconnav-item-font-size; }

.hook-iconnav-item-hover() {}

.hook-iconnav-item-active() {}


// Miscellaneous
// ========================================================================

.hook-iconnav-misc() {}


// Inverse
// ========================================================================

.hook-inverse-iconnav-item() {}
.hook-inverse-iconnav-item-hover() {}
.hook-inverse-iconnav-item-active() {}