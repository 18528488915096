//
// Component: Base
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@text-lead-font-family:                         @global-primary-font-family;
@text-lead-font-weight:                         @global-primary-font-weight;
@text-lead-text-transform:                      @global-primary-text-transform;
@text-lead-letter-spacing:                      @global-primary-letter-spacing;
@text-lead-font-style:                          @global-primary-font-style;

@text-meta-font-family:                         @global-secondary-font-family;
@text-meta-font-weight:                         @global-secondary-font-weight;
@text-meta-text-transform:                      @global-secondary-text-transform;
@text-meta-letter-spacing:                      @global-secondary-letter-spacing;
@text-meta-font-style:                          @global-secondary-font-style;


// Style modifiers
// ========================================================================

//
// Lead
//

.hook-text-lead() {}

.hook-text-lead() when not (@text-lead-font-family = inherit) {
    font-family: @text-lead-font-family;
}

.hook-text-lead() when not (@text-lead-font-weight = inherit) {
    font-weight: @text-lead-font-weight;
}

.hook-text-lead() when not (@text-lead-text-transform = inherit) {
    text-transform: @text-lead-text-transform;
}

.hook-text-lead() when not (@text-lead-letter-spacing = inherit) {
    letter-spacing: @text-lead-letter-spacing;
}

.hook-text-lead() when not (@text-lead-font-style = inherit) {
    font-style: @text-lead-font-style;
}

//
// Meta
//

.hook-text-meta() {}

.hook-text-meta() when not (@text-meta-font-family = inherit) {
    font-family: @text-meta-font-family;
}

.hook-text-meta() when not (@text-meta-font-weight = inherit) {
    font-weight: @text-meta-font-weight;
}

.hook-text-meta() when not (@text-meta-text-transform = inherit) {
    text-transform: @text-meta-text-transform;
}

.hook-text-meta() when not (@text-meta-letter-spacing = inherit) {
    letter-spacing: @text-meta-letter-spacing;
}

.hook-text-meta() when not (@text-meta-font-style = inherit) {
    font-style: @text-meta-font-style;
}


// Size modifiers
// ========================================================================

.hook-text-small() {}

.hook-text-large() {}


// Background modifier
// ========================================================================

.hook-text-background() {}


// Miscellaneous
// ========================================================================

.hook-text-misc() {}


// Inverse
// ========================================================================

.hook-inverse-text-lead() {}
.hook-inverse-text-meta() {}