// UIkit Core
@import "../node_modules/uikit/src/less/components/_import.less";

// Custom Fonts
//@import "aspire/_fonts.less";

// UIkit Theme
@import "master/_import.less";
@import "aspire/decor-variables.less";

// Lowen Common Theme
@import "common-theme.less";
// TheDesigner Theme
@import "common-designer-theme.less";

//// Branding
//// ========================================================================
// make icon buttons Aspire charcoal
@icon-button-background: @global-border;
//@icon-button-color: @global-background;
@icon-button-color: @global-link-color;

@inverse-global-border: #fff;
//Designer variables
@offcanvas-background: @global-emphasis-color;
@offcanvas-project-button: @DecorBrandColor;

@project-button-hover: @DecorBrandColor;
@project-button-hover-background: @global-emphasis-color;
@project-button-hover-shadow: @DecorBrandColor;

@texture-dropdown-background:@DecorLightColor;
@overlay-background: @DecorLightColor;
@overlay-background-expanded: @DecorLightColor;

@library-overlay-background: @global-emphasis-color;
@library-button-container-background:@DecorBrandColor;
@library-button-container:@global-emphasis-color;
@library-button:@global-emphasis-color;
@library-button-background: transparent;

#offcanvasNav {
  .uk-logo {
    img {
      //max-width: 300px;
      max-height: 50px;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
// padding the button for new line height;
.uk-offcanvas-bar {
  background-color: @offcanvas-background;
  .uk-button-primary.project-button {
    background-color: @offcanvas-project-button;
  }
}
.uk-button {
    // line-height: 1.5;
    padding-top: 4px;
}

.uk-button-primary {
    outline: 2px solid @global-primary-background;
    outline-offset: 3px;
}

.uk-button-secondary {
    outline: 2px solid @global-secondary-background;
    outline-offset: 3px;
}

.uk-button-danger {
    outline: 2px solid @global-danger-background;
    outline-offset: 3px;
}

.uk-button-default {
    border: none;
    outline: 2px solid @global-primary-background;
    outline-offset: 3px;
}

.uk-button-success {
    background-color: rgb(26, 200, 26);
    color: white;
    outline: 2px solid rgb(26, 200, 26)!important;
    outline-offset: 3px;
  }
  
  .uk-button-success:hover,
  .uk-button-success:focus {
    background-color: @global-background;
    color: @global-primary-background;
    outline: 2px solid rgb(26, 200, 26)!important;
    outline-offset: 3px;
  }

.uk-button-default:hover {
    color: @DecorBrandColor;
    background-color: @global-emphasis-color;
}

.tile-menu-dropdown-option:hover {
    text-decoration-line: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
    text-decoration-color: @DecorBrandColor;
}

.tile-menu-dropdown-option .unavailable {
    color: lighten(gray, 30%);
}

.tile-menu-dropdown-option .unavailable:hover {
    background-color: transparent!important;
}

.selection-span.uk-text-primary {
    text-decoration-line: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 3px;
    text-decoration-color: darken(@DecorBrandColor, 20%);
}

#BillingDetails,
#ShippingDetails {
    .uk-form-controls {
        .uk-input::placeholder {
            color: @global-warning-background;
        }
    }
}

.uk-input::placeholder {
  color: @global-warning-background;
}

.uk-logo.decor-logo {
  img {
    min-height: 50px;
    min-width: 250px;
  }
}

.dr-creation {
  position: absolute;
  right: 0;
  top: 0vh;
  //top: 7vh;
  height: 40vh;
  //top: 27vh;
  //height: 35vh;
}

.dr-button {
  background-color:@global-emphasis-color;
}