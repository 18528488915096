//
// Component: Marker
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@marker-border-radius:                          500px;


// Component
// ========================================================================

.hook-marker() when not (@marker-border-radius = 0) {
    border-radius: @marker-border-radius;
}

.hook-marker-hover() {}


// Miscellaneous
// ========================================================================

.hook-marker-misc() {}


// Inverse
// ========================================================================

.hook-inverse-marker() {}
.hook-inverse-marker-hover() {}
