//
// Component: Dotnav
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-dotnav() {}

.hook-dotnav-item() {
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border-color, box-shadow;
}

.hook-dotnav-item-hover() {}

.hook-dotnav-item-onclick() {}

.hook-dotnav-item-active() {}


// Miscellaneous
// ========================================================================

.hook-dotnav-misc() {}


// Inverse
// ========================================================================

.hook-inverse-dotnav() {}
.hook-inverse-dotnav-item() {}
.hook-inverse-dotnav-item-hover() {}
.hook-inverse-dotnav-item-onclick() {}
.hook-inverse-dotnav-item-active() {}