//
// Component: Iconnav
//
// ========================================================================


// Variables
// ========================================================================


// Component
// ========================================================================

.hook-iconnav() {}

.hook-iconnav-item() {
    transition: 0.1s ease-in-out;
    transition-property: color, background-color, border-color, box-shadow;
}

.hook-iconnav-item-hover() {}

.hook-iconnav-item-active() {}


// Miscellaneous
// ========================================================================

.hook-iconnav-misc() {}


// Inverse
// ========================================================================

.hook-inverse-iconnav-item() {}
.hook-inverse-iconnav-item-hover() {}
.hook-inverse-iconnav-item-active() {}