//
// Component: Alert
//
// ========================================================================


// Variables
// ========================================================================

//
// New
//

@alert-border-mode:                             ~'';

@alert-border-width:                            0;
@alert-border:                                  transparent;

@alert-primary-border:                          transparent;
@alert-success-border:                          transparent;
@alert-warning-border:                          transparent;
@alert-danger-border:                           transparent;


// Component
// ========================================================================

.hook-alert() when not (@alert-border-width = 0) {
    border@{alert-border-mode}: @alert-border-width solid @alert-border;
}


// Close
// ========================================================================

.hook-alert-close() {}

.hook-alert-close-hover() {}


// Style modifiers
// ========================================================================

.hook-alert-primary() when not (@alert-border-width = 0) {
    border@{alert-border-mode}-color: @alert-primary-border;
}

.hook-alert-success() when not (@alert-border-width = 0) {
    border@{alert-border-mode}-color: @alert-success-border;
}

.hook-alert-warning() when not (@alert-border-width = 0) {
    border@{alert-border-mode}-color: @alert-warning-border;
}

.hook-alert-danger() when not (@alert-border-width = 0) {
    border@{alert-border-mode}-color: @alert-danger-border;
}


// Miscellaneous
// ========================================================================

.hook-alert-misc() {}
