//
// Component: Form
//
// ========================================================================


// Variables
// ========================================================================

@form-line-height:                              @form-height - (@form-border-width * 2);
@form-small-line-height:                        @form-small-height - (@form-border-width * 2);
@form-large-line-height:                        @form-large-height - (@form-border-width * 2);

//
// New
//

@form-border-mode:                              ~'';

@form-border-width:                             0;
@form-border:                                   transparent;

@form-focus-border:                             transparent;

@form-disabled-border:                          transparent;

@form-danger-border:                            transparent;
@form-success-border:                           transparent;

@form-blank-focus-border:                       transparent;
@form-blank-focus-border-style:                 solid;

@form-radio-border-width:                       0;
@form-radio-border:                             transparent;

@form-radio-focus-border:                       transparent;

@form-radio-checked-border:                     transparent;

@form-radio-disabled-border:                    transparent;


// Component
// ========================================================================

.hook-form() {}

.hook-form-single-line() when not (@form-border-width = 0) {
    border@{form-border-mode}: @form-border-width solid @form-border;
}

.hook-form-multi-line() when not (@form-border-width = 0) {
    border@{form-border-mode}: @form-border-width solid @form-border;
}

// `!important` needed to override single line selector for `uk-select`

.hook-form-focus() when not (@form-border-width = 0) {
    border-color: @form-focus-border !important;
}

.hook-form-disabled() when not (@form-border-width = 0) {
    border-color: @form-disabled-border !important;
}


// Style modifiers
// ========================================================================

.hook-form-danger() when not (@form-border-width = 0) {
    border-color: @form-danger-border !important;
}

.hook-form-success() when not (@form-border-width = 0) {
    border-color: @form-success-border !important;
}

.hook-form-blank() when not (@form-border-width = 0) {
    border-color: transparent !important;
}

.hook-form-blank-focus() when not (@form-border-width = 0) {
    border-color: @form-blank-focus-border !important;
}

.hook-form-blank-focus() when not (@form-blank-focus-border-style = solid) {
    border-style: @form-blank-focus-border-style;
}


// Radio and checkbox
// ========================================================================

.hook-form-radio() when not (@form-radio-border-width = 0) {
    border: @form-radio-border-width solid @form-radio-border;

}

.hook-form-radio-focus() when not (@form-radio-border-width = 0) {
    border-color: @form-radio-focus-border;
}

.hook-form-radio-checked() when not (@form-radio-border-width = 0) {
    border-color: @form-radio-checked-border;
}

.hook-form-radio-checked-focus() {}

.hook-form-radio-disabled() when not (@form-radio-border-width = 0) {
    border-color: @form-radio-disabled-border;
}


// Legend
// ========================================================================

.hook-form-legend() {}


// Label
// ========================================================================

.hook-form-label() {}


// Layout
// ========================================================================

.hook-form-stacked-label() {}

.hook-form-horizontal-label() {}


// Icon
// ========================================================================

.hook-form-icon() {}


// Miscellaneous
// ========================================================================

.hook-form-misc() {}


// Inverse
// ========================================================================

@inverse-form-border:                                   transparent;
@inverse-form-focus-border:                             transparent;
@inverse-form-radio-border:                             transparent;
@inverse-form-radio-focus-border:                       transparent;
@inverse-form-radio-checked-border:                     transparent;

.hook-inverse-form() when not (@form-border-width = 0) {
    border-color: @inverse-form-border !important;
}
.hook-inverse-form-focus() when not (@form-border-width = 0) {
    border-color: @inverse-form-focus-border !important;
}

.hook-inverse-form-radio() when not (@form-border-width = 0) {
    border-color: @inverse-form-radio-border;
}
.hook-inverse-form-radio-focus() when not (@form-border-width = 0) {
    border-color: @inverse-form-radio-focus-border;
}

.hook-inverse-form-radio-checked() when not (@form-border-width = 0) {
    border-color: @inverse-form-radio-checked-border;
}
.hook-inverse-form-radio-checked-focus() {}

.hook-inverse-form-label() {}

.hook-inverse-form-icon() {}
