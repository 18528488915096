.uk-button-basic {
    outline: none;
    outline-offset: 0px;
    border: none;
}
// Create project button
.project-button {
    outline: 2px solid @global-primary-background;
    outline-offset: 3px;
    transition: box-shadow 0.2s ease-in-out, outline-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.project-button:hover {
    background-color: @project-button-hover-background;
    color: @project-button-hover;
    box-shadow: 0 0 0 3px @project-button-hover-shadow;
}

.design-status-container {
    background-color: @global-background;
    border: none;
    border-radius: 0 0 0 20px;
}

.textureControls {
    //background-color: white !important;
    min-width: 640px;
    .uk-form-controls {
        .uk-input {
            background-color: lighten(@overlay-background, 50%);
            color: @global-primary-background;
            
        }
    }
}

.input-text {
    text-align: center;
}

.texture-dropdown {
    background-color: lighten(@texture-dropdown-background, 50%);
    width: stretch;
    outline: none;
    border: none;
    height: 40px;
    margin-right: 10px;
    padding-left: 10px;
}

.overlay-container {
    position: absolute;
    top: 8%;
    bottom: 16px;
    left: calc(8% + 36px);
    right: calc(8% - 8px);
    overflow: hidden;
    pointer-events: none;
}

.overlay {
    position: relative;
    color: white;
    background-color: @overlay-background;
    min-height: 100%;
    top: calc(100% - 50px);
    right: calc(100% - 300px);
    z-index: 1;
    pointer-events: all;
    transition: right 0.3s ease-in-out, top 0.3s ease-in-out;
}

.overlay-expanded {
    position: relative;
    min-height: 100%;
    top: 0;
    right: 0;
    background-color: @overlay-background-expanded;
}

.overlay-content-container {
    position: relative;
}


.library-overlay-container {
    position: absolute;
    top: 8%;
    bottom: 16px;
    left: calc(8% + 36px);
    right: calc(8% - 8px);
    overflow: hidden;
    pointer-events: none;
}

.library-overlay {
    position: relative;
    box-sizing: border-box;
    color: white;
    background-color: @library-overlay-background;
    min-height: 0;
    // top: 5%;
    bottom: 0;
    right: calc(100% );
    z-index: 1;
    pointer-events: all;
    transition: right 0.3s ease-in-out, min-height 0.3s ease-in-out;
}

.library-overlay-expanded {
    position: relative;
    // top: 5%;
    min-height: 100%;
    // bottom: 1000px;
    right: 0;
    background-color: @library-overlay-background;
}

.library-overlay-content-container {
    position: relative;
}

.expand {
    position: relative;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
}

.library-btn-container {
    position: absolute;
    top: 32px;
    left: calc(8% + 37px);
    width: 151px;
    height: 50px; 
    // box-shadow: 0 -0 20px rgba(0, 0, 0, 0.25);
    background-color: @library-button-container-background;
    color: @library-button-container;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px 20px 0 0;
}

.library-btn-icon {
    margin-right: 8px;
}

.library-btn {
    height: 100%;
    width: 100%;
    border: none;
    padding: none;
    color: @library-button;
    //background-color: @Mindaro;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.reload-btn-container {
    position: absolute;
    top: 35px;
    left: calc(8% + 150px);
    width: fit-content;
    height: 47px;
    background-color: @library-overlay-background;
    color: gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0 20px 0 0;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.reload-btn {
    height: 100%;
    width: 100%;
    padding-left: 50px;
    padding-right: 20px;
    border: none;
    color: @offcanvas-project-button;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.order-btn-container {
    position: absolute;
    bottom: 16px;
    left: calc(8% - 4px);
    width: 302px;
    margin-left: 40px;
    // to match architexture style
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.25);
    z-index: 4;
}

.order-btn {
    width: 100%;
    height: 50px;
    border: none;
    background-color: @offcanvas-project-button;
    padding: 0px 10px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    z-index: 5;
}

// button hover on TheDesigner

.library-btn:hover,
.library-btn-focus,
.order-btn:hover,
.order-btn-focus {
    background-color: @global-background;
    color: @offcanvas-background;
}

.reload-btn-container:hover,
.reload-btn-container-focus {
    background-color: white;
    color: @offcanvas-background;
}

.reload-btn:hover,
.reload-btn:focus {
    color: @offcanvas-background;
}

.horizontal-line {
    content: '';
    border-top: 1px solid white;
}

.vertical-line {
    content: '';
    min-width: 1px;
    min-height: 100%;
    background-color: white;
    margin-inline: 40px;
}

.menu-tile.uk-card-primary {
    transition: box-shadow 0.2s ease-in-out, color 0.2s ease-in-out, font-size 0.2s ease-in-out;
}

.menu-tile.uk-card-primary:hover,
.menu-tile.uk-card-primary:focus {
    box-shadow: inset 0 0 0 5px @offcanvas-project-button;
    color: @offcanvas-project-button;
    font-size: 0.95em;
}

@media (max-width: 1100px) {

    .designer-start {
        padding-top: 20px;
    }

    .overlay-container {
        top: 5px;
        bottom: 5px;
        left: 25px;
        right: 5px;
    }
    
    .overlay {
        top: 100%;
        right: 50%;
    }
    
    .overlay-expanded {
        top: 0;
        right: 0;
    }

    .library-overlay-container {
        top: 5px;
        bottom: 5px;
        left: 25px;
        right: 5px;
    }
    
    .library-overlay {
        top: 100%;
        left: 100%;
        min-height: 100%;
        transition: top 0.3s ease-in-out, min-height 0.3s ease-in-out, right 0.3s ease-in-out, left 0.3s ease-in-out;
    }
    
    .library-overlay-expanded {
        top: 0;
        left: 0;
    }

    .library-btn-container {
        top: 100%;
        left: calc(50%);
        right: 0;
        border-radius: 0;
        margin: 0;
        width: 50%;
        padding: 0;
        justify-content: center;
        background-color: @overlay-background;
        box-shadow: none;
    }

    .library-btn {
        color: white;
    }

    .reload-btn-container {
        top: -50px;
        left: 50%;
        border-radius: 20px 20px 0 0;
        margin: 0;
        padding: 0;
        justify-content: center;
        background-color: @global-background;
        box-shadow: none;
    }

    .reload-btn {
        color: @offcanvas-background;
        padding-inline: 20px;
        cursor: pointer;
        z-index: 1000;
    }

    .order-btn-container {
        bottom: 0;
        top: 100%;
        left: 20px;
        padding: 0;
        margin: 0;
        width: calc(50% - 20px);
    }

    .order-btn{
        padding: 0;
    }

    .design-status-container {
        top: -50px;
    }

}


@media (max-width: 959px) {
    .designer-start {
        padding-top: 0;
    }
}