//@internal-fonts: 'Montserrat:400,500,600|Taviraj:200,300';
// === Fonts
@global-font-family: 'Noto Sans Display', 'Open Sans', sans-serif;
@global-font-size: 16px;
@global-line-height: 1.625;
@global-2xlarge-font-size: 43px;
@global-xlarge-font-size: 32px;
@global-large-font-size: 28px;
@global-medium-font-size: 24px;
@global-small-font-size: 14px;
@global-primary-font-family: 'Noto Sans Display', 'Open Sans', sans-serif;
@global-primary-font-weight: 200;
@global-primary-text-transform: inherit;
@global-primary-letter-spacing: inherit;
@global-primary-font-style: inherit;
@global-secondary-font-family: 'Noto Sans Display', 'Open Sans', sans-serif;
@global-secondary-font-weight: 500;
@global-secondary-text-transform: none;
@global-secondary-letter-spacing: 0;
@global-secondary-font-style: inherit;

// BrandColors
@DecorBrandColor: #8e391b;
@DecorLightColor: #c47857;
@DecorEmphasisColor: #5c301c;

@Mindaro: @DecorBrandColor;
@Charcoal: @DecorLightColor;
@DarkJungleGreen: @global-emphasis-color;

// === Text Colors
//@global-color: @Charcoal;
@global-color: @DarkJungleGreen;
//@global-emphasis-color: @DarkJungleGreen;
@global-emphasis-color: @Charcoal;
@global-muted-color: lighten(@global-color, 50%);
@global-link-color: darken(@Mindaro, 25%);
@global-link-hover-color: @Mindaro;
//@global-inverse-color: #FAF9F1;
@global-inverse-color: #FFF;


// === Background Colors
//@global-background: #FFF;
@global-background: #FAF9F1;
@global-muted-background: lighten(@Charcoal,25%);
//@global-muted-background: @Mindaro;
@global-primary-background: @DarkJungleGreen;
@global-secondary-background: @Charcoal;
@global-success-background: @Mindaro;
@global-warning-background: #FF9E45;
@global-danger-background: #E44E56;


// === Start computing....
@global-border-width: 1px;
@global-border: #E1E0D8;
@global-border-radius: 0;
@global-small-box-shadow: 0 1px 4px rgba(40,67,88,0.14);
@global-medium-box-shadow: 0 5px 15px rgba(40,67,88,0.08);
@global-large-box-shadow: 0 14px 25px rgba(40,67,88,0.16);
@global-xlarge-box-shadow: 0 28px 50px rgba(40,67,88,0.16);
@global-margin: 20px;
@global-small-margin: 10px;
@global-medium-margin: 40px;
@global-large-margin: 70px;
@global-xlarge-margin: 140px;
@global-gutter: 20px;
@global-small-gutter: 10px;
@global-medium-gutter: 40px;
@global-large-gutter: 70px;
@global-control-height: 40px;
@global-control-small-height: 30px;
@global-control-large-height: 55px;
@global-z-index: 1000;
@base-link-hover-text-decoration: none;
@base-code-color: @global-emphasis-color;
@base-em-color: @global-emphasis-color;
@base-ins-background: darken(@global-muted-background, 3%);
@base-mark-background: darken(@global-muted-background, 3%);
@base-h2-line-height: 1.5;
@base-h3-line-height: 1.3;
@base-h4-line-height: 1.5;
@base-h5-font-size: 21px;
@base-h5-line-height: 1.5;
@base-h6-line-height: 1.6;
@base-blockquote-font-style: normal;
@base-blockquote-footer-margin-top: @global-medium-margin;
@base-selection-background: lighten(@global-secondary-background, 10%);
@base-code-padding-horizontal: 6px;
@base-code-padding-vertical: 2px;
@base-code-border-width: @global-border-width;
@base-code-border: @global-border;
@base-code-border-radius: 1px;
@base-pre-padding: 10px;
@base-pre-background: @global-background;
@base-pre-border-width: @global-border-width;
@base-pre-border: @global-border;
@base-pre-border-radius: @global-border-radius;
@base-h2-font-weight: 300;
@base-h3-font-weight: 300;
@base-h4-font-family: @base-heading-font-family;
@base-h4-font-weight: 300;
@base-h5-font-family: @base-heading-font-family;
@base-h5-font-weight: 300;
@base-h6-font-weight: 600;
@base-h6-text-transform: uppercase;
@base-h6-letter-spacing: 1.5px;
@base-blockquote-footer-em-dash: false;
@base-blockquote-footer-letter-spacing: @global-secondary-letter-spacing;
@inverse-base-link-color: @inverse-global-emphasis-color;
@inverse-base-link-hover-color: fade(@inverse-global-color, 70%);
@inverse-base-heading-color: @inverse-global-emphasis-color;
@inverse-base-code-border: @inverse-global-border;
@heading-small-font-size: 44px;
@heading-medium-font-size: 47px;
@heading-large-font-size: 52px;
@heading-xlarge-font-size: 67px;
@heading-2xlarge-font-size: 97px;
@heading-small-font-size-m: 55px;
@heading-medium-font-size-m: 59px;
@heading-large-font-size-m: 66px;
@heading-xlarge-font-size-m: 90px;
@heading-medium-font-size-l: 76px;
@heading-xlarge-font-size-l: 124px;
@heading-2xlarge-font-size-l: 180px;
@heading-large-text-transform: uppercase;
@heading-xlarge-text-transform: uppercase;
@heading-2xlarge-text-transform: uppercase;
@divider-icon-height: 30px;
@divider-icon-color: @global-primary-background;
@internal-divider-icon-image: "../../../../uikit-themes/master-gravity-tower/images/divider-icon.svg";
@list-striped-background: @global-muted-background;
@list-bullet-icon-color: darken(@global-border, 8%);
@internal-list-bullet-image: "../../../../uikit-themes/master-gravity-tower/images/list-bullet.svg";
@table-row-active-background: fade(@global-secondary-background, 4%);
@table-divider-border: @global-border;
@table-striped-row-background: fade(@global-secondary-background, 4%);
@icon-button-background: @global-background;
@icon-button-color: @global-color;
@icon-button-hover-background: @global-background;
@icon-button-hover-color: @global-primary-background;
@icon-button-active-background: @global-primary-background;
@icon-button-active-color: @global-inverse-color;
@icon-button-border-width: @global-border-width;
@icon-button-border: @global-border;
@icon-button-hover-border: @icon-button-hover-color;
@icon-button-active-border: @icon-button-active-background;
@inverse-icon-button-background: transparent;
@inverse-icon-button-color: @inverse-global-color;
@inverse-icon-button-hover-background: transparent;
@inverse-icon-button-hover-color: @inverse-global-emphasis-color;
@inverse-icon-button-active-background: @inverse-global-primary-background;
@inverse-icon-button-active-color: @inverse-global-inverse-color;
@inverse-icon-button-border: @inverse-global-border;
@inverse-icon-button-hover-border: fade(@inverse-global-color, 20%);
@form-range-thumb-height: 11px;
@form-range-thumb-background: @global-secondary-background;
@form-range-track-height: @global-border-width;
@form-range-track-background: @global-border;
@form-range-track-focus-background: @global-secondary-background;
@form-padding-horizontal: 0;
@form-background: transparent;
@form-focus-background: transparent;
@form-disabled-background: transparent;
@form-danger-color: @form-color;
@form-success-color: @form-color;
@form-radio-background: transparent;
@form-radio-checked-background: darken(@global-primary-background, 10%);
@form-radio-checked-focus-background: @global-primary-background;
@form-stacked-margin-bottom: 5px;
@form-border-mode: -bottom;
@form-border-width: @global-border-width;
@form-border: @global-border;
@form-focus-border: @global-primary-background;
@form-disabled-border: @global-border;
@form-danger-border: @global-danger-background;
@form-success-border: @global-success-background;
@form-blank-focus-border: @global-border;
@form-radio-border-width: @global-border-width;
@form-radio-border: darken(@global-border, 10%);
@form-radio-focus-border: @global-primary-background;
@form-radio-checked-border: transparent;
@form-radio-disabled-border: @global-border;
@inverse-form-background: transparent;
@inverse-form-focus-background: transparent;
@inverse-form-radio-background: transparent;
@inverse-form-radio-focus-background: transparent;
@inverse-form-border: @inverse-global-border;
@inverse-form-focus-border: @inverse-global-primary-background;
@inverse-form-radio-border: @inverse-global-border;
@inverse-form-radio-focus-border: @inverse-global-primary-background;
@button-small-line-height: @global-control-small-height;
@button-large-font-size: 17px;
@button-small-padding-horizontal: 18px;
@button-default-background: transparent;
//@button-default-hover-color: darken(@global-primary-background, 2%);
@button-default-hover-color: @Mindaro;
@button-default-active-color: darken(@button-default-hover-color, 3%);
@button-primary-hover-background: @global-secondary-background;
@button-primary-hover-color: @global-background;
@button-primary-active-background: @global-secondary-background;
@button-secondary-color: @global-background;
@button-secondary-hover-background: @global-primary-background;
@button-secondary-hover-color: @global-background;
@button-secondary-active-background: @global-primary-background;
@button-link-hover-color: darken(@global-secondary-background, 10%);
@button-text-mode: line;
@button-text-border-width: 2px;
@button-border-width: 2px;
@button-default-border: @global-emphasis-color;
@button-default-hover-border: @global-primary-background;
@button-default-active-border: darken(@global-primary-background, 3%);
@button-border-radius: 500px;
@button-small-border-radius: @button-border-radius;
@button-large-border-radius: @button-border-radius;
.hook-button-text() when (@button-text-mode = line) {
    &::before { right: 60%; }
}
@inverse-button-default-background: @inverse-global-muted-background;
@inverse-button-default-color: @inverse-global-color;
@inverse-button-default-hover-background: transparent;
@inverse-button-default-hover-color: @inverse-global-emphasis-color;
@inverse-button-default-active-background: transparent;
@inverse-button-default-active-color: @inverse-global-muted-color;
@inverse-button-default-border: transparent;
@inverse-button-default-hover-border: @inverse-global-border;
@inverse-button-default-active-border: @inverse-global-primary-background;
@inverse-button-primary-background: @inverse-global-primary-background;
@inverse-button-primary-color: @inverse-global-inverse-color;
@inverse-button-primary-hover-background: transparent;
@inverse-button-primary-hover-color: @inverse-global-emphasis-color;
@inverse-button-primary-active-background: transparent;
@inverse-button-primary-active-color: @inverse-global-color;
@inverse-button-primary-border: transparent;
@inverse-button-primary-hover-border: @inverse-global-border;
@inverse-button-primary-active-border: @inverse-global-primary-background;
@inverse-button-secondary-background: @inverse-global-primary-background;
@inverse-button-secondary-color: @inverse-global-inverse-color;
@inverse-button-secondary-hover-background: transparent;
@inverse-button-secondary-hover-color: @inverse-global-emphasis-color;
@inverse-button-secondary-active-background: transparent;
@inverse-button-secondary-active-color: @inverse-global-color;
@inverse-button-secondary-border: transparent;
@inverse-button-secondary-hover-border: @inverse-global-border;
@inverse-button-secondary-active-border: @inverse-global-primary-background;
@inverse-button-link-color: @inverse-global-color;
@inverse-button-link-hover-color: @inverse-global-emphasis-color;
@progress-height: 10px;
@progress-border-radius: 0px;
//@card-hover-background: @global-muted-background;
@card-hover-background: @Mindaro;
@card-default-background: @global-background;
//@card-default-hover-background: darken(@global-muted-background, 2%);
@card-default-hover-background: darken(@Mindaro, 2%);
@card-primary-hover-background: darken(@card-primary-background, 3%);
@card-title-font-weight: 300;
@marker-background: @global-primary-background;
@marker-color: @global-inverse-color;
@marker-hover-background: @global-secondary-background;
@totop-color: @global-inverse-color;
@totop-hover-color: @global-inverse-color;
@totop-active-color: darken(@global-inverse-color, 3%);
@totop-background: @global-secondary-background;
@totop-hover-background: @global-primary-background;
@totop-active-background: darken(@global-primary-background, 3%);
@internal-totop-mode: 'animate';
@totop-border-radius: 500px;
@totop-padding: 20px;
@alert-background: @global-background;
@alert-primary-background: @global-background;
@alert-success-background: @global-background;
@alert-warning-background: @global-background;
@alert-danger-background: @global-background;
@alert-border-width: @global-border-width;
@alert-border: @global-border;
@alert-primary-border: @global-primary-background;
@alert-success-border: @global-success-background;
@alert-warning-border: @global-warning-background;
@alert-danger-border: @global-danger-background;
@overlay-primary-background: fade(@global-primary-background, 80%);
@comment-title-font-size: @global-font-size;
@comment-title-line-height: @global-line-height;
@search-placeholder-color: @global-muted-color;
@search-icon-color: @global-emphasis-color;
@search-default-background: transparent;
@search-navbar-font-size: @global-medium-font-size;
@search-navbar-icon-padding: 5px;
@search-large-font-size: @global-xlarge-font-size;
@search-toggle-color: @global-emphasis-color;
@search-toggle-hover-color: @global-primary-background;
@search-default-icon-width: 20px;
@search-default-icon-padding: 10px;
@search-large-icon-width: 40px;
@search-large-icon-padding: 20px;
@search-default-border-mode: -bottom;
@search-default-border-width: @global-border-width;
@search-default-border: @global-secondary-background;
@search-default-focus-border: @global-primary-background;
@inverse-search-default-background: transparent;
@inverse-search-default-focus-background: transparent;
@inverse-search-default-border: @inverse-global-border;
@inverse-search-default-focus-border: @inverse-global-primary-background;
@accordion-item-padding-top: @global-margin;
@accordion-item-border-width: @global-border-width;
@accordion-item-border: @global-border;
@accordion-title-font-family: @global-secondary-font-family;
@accordion-title-font-weight: @global-secondary-font-weight;
@accordion-title-text-transform: @global-secondary-text-transform;
@accordion-title-letter-spacing: @global-secondary-letter-spacing;
@accordion-title-font-style: @global-secondary-font-style;
@dropdown-background: @global-background;
@dropdown-padding: 25px;
@dropdown-nav-item-color: @global-color;
@dropdown-nav-item-hover-color: @global-emphasis-color;
@dropdown-box-shadow: @global-small-box-shadow;
@dropdown-nav-font-size: @global-small-font-size;
@dropdown-nav-item-padding-vertical: 6px;
@dropdown-nav-divider-margin-horizontal: -@dropdown-padding;
@dropdown-nav-subtitle-color: @global-muted-color;
@dropdown-nav-subtitle-font-size: @global-small-font-size;
@dropbar-padding-top: 0;
@dropbar-padding-bottom: 20px;
@dropbar-background: @global-background;
@dropbar-top-box-shadow: 0 6px 4px -3px rgba(41,57,69,0.1);
@dropbar-bottom-box-shadow: 0 -6px 4px -3px rgba(41,57,69,0.1);
@dropbar-left-box-shadow: 18px 0 10px -18px rgba(41,57,69,0.1);
@dropbar-right-box-shadow: -18px 0 10px -18px rgba(41,57,69,0.1);
@tooltip-background: @global-primary-background;
@countdown-item-color: @global-emphasis-color;
@countdown-item-font-family: @global-secondary-font-family;
@countdown-item-font-weight: @global-secondary-font-weight;
@countdown-item-text-transform: @global-secondary-text-transform;
@countdown-item-letter-spacing: 0;
@countdown-item-font-style: @global-secondary-font-style;
@nav-header-font-size: 15px;
@nav-default-font-size: 18px;
@nav-default-item-color: @global-secondary-background;
@nav-default-item-hover-color: @global-primary-background;
@nav-default-item-active-color: @global-primary-background;
@nav-default-subtitle-font-size: @global-font-size;
@nav-default-sublist-item-color: fade(@global-emphasis-color, 65%);
@nav-default-sublist-item-hover-color: @global-link-color;
@nav-default-sublist-item-active-color: @global-link-hover-color;
@nav-primary-item-color: @global-emphasis-color;
@nav-primary-item-hover-color: @global-link-color;
@nav-primary-item-active-color: @global-link-hover-color;
@nav-primary-header-color: @global-emphasis-color;
@nav-primary-sublist-item-color: @global-emphasis-color;
@nav-primary-sublist-item-hover-color: @global-link-color;
@nav-primary-sublist-item-active-color: @global-link-hover-color;
@nav-secondary-item-color: @global-emphasis-color;
@nav-secondary-item-hover-color: @global-link-color;
@nav-secondary-item-active-color: @global-link-hover-color;
@nav-secondary-subtitle-color: @global-emphasis-color;
@nav-secondary-subtitle-hover-color: @global-link-color;
@nav-secondary-subtitle-active-color: @global-link-hover-color;
@nav-secondary-sublist-item-color: fade(@global-emphasis-color, 65%);
@nav-secondary-sublist-item-hover-color: @global-link-color;
@nav-secondary-sublist-item-active-color: @global-link-hover-color;
@nav-default-font-family: @global-primary-font-family;
@inverse-nav-secondary-subtitle-hover-color: @inverse-global-emphasis-color;
@navbar-background: @global-background;
@navbar-gap: 25px;
@navbar-nav-item-color: @global-secondary-background;
@navbar-nav-item-font-size: 18px;
@navbar-toggle-color: @global-primary-background;
@navbar-toggle-hover-color: @global-secondary-background;
@navbar-subtitle-font-size: @global-font-size;
@navbar-dropdown-shift-margin: -@navbar-dropdown-padding;
@navbar-dropdown-padding: 30px;
@navbar-dropdown-dropbar-large-shift-margin: -@navbar-dropdown-padding;
@navbar-backdrop-filter: blur(40px);
@navbar-padding-top: 0px;
@navbar-padding-top-m: 15px;
@navbar-nav-item-line-mode: true;
@navbar-nav-item-line-position-mode: top;
@navbar-nav-item-line-margin-vertical: 12px;
@navbar-nav-item-line-margin-horizontal: ~'calc(50% - (@{navbar-nav-item-line-height} / 2))';
@navbar-nav-item-line-height: 6px;
@navbar-nav-item-line-border-radius: 500px;
@navbar-nav-item-line-hover-height: @navbar-nav-item-line-height;
@navbar-nav-item-line-hover-background: @global-secondary-background;
@navbar-nav-item-line-onclick-height: @navbar-nav-item-line-height;
@navbar-nav-item-line-onclick-background: @global-secondary-background;
@navbar-nav-item-line-active-height: @navbar-nav-item-line-height;
@navbar-nav-item-line-active-background: @global-secondary-background;
@navbar-mode: border;
@navbar-mode-border-vertical: all;
@navbar-dropdown-nav-item-active-color: @global-secondary-background;
@navbar-dropdown-nav-item-color: fade(@global-emphasis-color, 90%);
@navbar-dropdown-nav-header-color: @global-secondary-background;
@navbar-dropdown-nav-sublist-item-active-color: @global-secondary-background;
@navbar-dropdown-nav-font-size: @global-small-font-size;
@subnav-item-color: @global-color;
@subnav-item-hover-color: @global-primary-background;
@subnav-item-active-color: @global-primary-background;
@subnav-divider-border-height: 1em;
@subnav-pill-item-padding-vertical: 7px;
@subnav-pill-item-padding-horizontal: 14px;
@subnav-pill-item-hover-background: @global-secondary-background;
@subnav-pill-item-hover-color: @global-inverse-color;
@subnav-pill-item-onclick-background: lighten(@global-secondary-background, 90%);
@subnav-item-font-size: @global-small-font-size;
@inverse-subnav-item-color: @inverse-global-color;
@inverse-subnav-item-hover-color: @inverse-global-emphasis-color;
@inverse-subnav-pill-item-hover-color: @inverse-global-emphasis-color;
@inverse-subnav-pill-item-active-background: @inverse-global-primary-background;
@pagination-margin-horizontal: 25px;
@pagination-item-color: fade(@global-color, 50%);
@pagination-item-border-width: 0;
@pagination-item-font-size: 18px;
@inverse-pagination-item-hover-color: @inverse-global-emphasis-color;
@inverse-pagination-item-hover-border: @inverse-global-border;
@inverse-pagination-item-active-border: @inverse-global-primary-background;
@tab-item-color: @global-color;
@tab-border: transparent;
@tab-item-hover-border: @global-border;
@tab-item-font-size: @global-small-font-size;
@tab-item-line-height: 20px;
@inverse-tab-border: transparent;
@inverse-tab-item-hover-border: @inverse-global-border;
@inverse-tab-item-hover-color: @inverse-global-muted-color;
@slidenav-padding-horizontal: 15px;
@slidenav-padding-vertical: 15px;
@slidenav-color: @global-color;
@slidenav-hover-color: @global-background;
@slidenav-active-color: fade(@global-background, 96%);
@slidenav-large-padding-horizontal: 25px;
@slidenav-large-padding-vertical: 25px;
@slidenav-background: @global-background;
@slidenav-hover-background: @global-primary-background;
@slidenav-active-background: darken(@global-primary-background, 5%);
@slidenav-margin: 5px;
@dotnav-margin-horizontal: 15px;
@dotnav-item-width: 9px;
@dotnav-item-hover-background: @global-primary-background;
@dotnav-item-onclick-background: fade(@dotnav-item-hover-background, 20%);
@dotnav-item-active-background: @dotnav-item-hover-background;
@inverse-dotnav-item-onclick-background: @inverse-dotnav-item-background;
@inverse-dotnav-item-active-background: @inverse-dotnav-item-hover-background;
@thumbnav-item-background: fade(@global-secondary-background, 4%);
@thumbnav-item-border-width: 1px;
@thumbnav-item-hover-border: @global-secondary-background;
@thumbnav-item-active-border: @global-secondary-background;
@inverse-thumbnav-item-background: transparent;
@inverse-thumbnav-item-hover-border: @global-inverse-color;
@inverse-thumbnav-item-active-border: @global-inverse-color;
@iconnav-item-color: @global-emphasis-color;
@iconnav-item-hover-color: @global-muted-color;
@iconnav-item-active-color: @global-emphasis-color;
@text-lead-font-size: @global-medium-font-size;
@text-large-font-size: @global-medium-font-size;
@text-lead-font-weight: 400;
@internal-text-background-color-gradient: linear-gradient(135deg, lighten(@global-primary-background, 15%), @global-primary-background);
@background-secondary-background: @global-muted-background;
@logo-font-size: @global-medium-font-size;
@logo-font-family: @global-secondary-font-family;
@logo-font-weight: @global-secondary-font-weight;
@logo-text-transform: @global-secondary-text-transform;
@logo-letter-spacing: @global-secondary-letter-spacing;
@inverse-global-muted-color: fade(@inverse-global-color, 40%);
@inverse-global-border: fade(@inverse-global-color, 10%);
@inverse-icon-link-color: @global-background;
@inverse-icon-link-hover-color: @global-primary-background;
@inverse-icon-link-active-color: @global-primary-background;
@inverse-marker-hover-background: @global-primary-background;
@inverse-marker-hover-color: @global-background;
@inverse-slidenav-color: fade(@global-secondary-background, 80%);